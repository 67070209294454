import SliceCards from "./components/SliceCards";
import AuthorizeSpeaksFor from "./components/AuthorizeSpeaksFor";
import ProjectInviteUrl from "./components/ProjectInviteUrl";

const $ = require("jquery");
import "foundation-sites/js/foundation";
import * as ReactDOM from "react-dom";
import React from "react";
import {MemberTotals,NewMemberStatistics} from "./components/MemberStatistics";
import {ProjectTotals,NewProjectStatistics} from "./components/ProjectStatistics";

window.$ = $;
window.jQuery = $;

function showTotalMemberStatistics(data, prettyNames, domContainer){
    ReactDOM.render((<MemberTotals data={data} prettyNames={prettyNames} />), domContainer);
}
function showNewMemberStatistics(data, prettyNames, domContainer){
    ReactDOM.render((<NewMemberStatistics data={data} prettyNames={prettyNames} />), domContainer);
}
function showTotalProjectStatistics(data, prettyNames, domContainer){
    ReactDOM.render((<MemberTotals data={data} prettyNames={prettyNames} />), domContainer);
}
function showNewProjectStatistics(data, prettyNames, domContainer){
    ReactDOM.render((<NewMemberStatistics data={data} prettyNames={prettyNames} />), domContainer);
}

function showSliceCards(slices, projects, domContainer) {
    ReactDOM.render((<SliceCards slices={slices} projects={projects}/>), domContainer);
}


function showAuthorizeSpeaksFor(tool, csrf_token, domContainer) {
    ReactDOM.render((<AuthorizeSpeaksFor tool={tool} csrf_token={csrf_token}/>), domContainer);
}

function showProjectInviteUrl(invite_url_api, csrf_token, domContainer) {
    ReactDOM.render((<ProjectInviteUrl inviteUrlApi={invite_url_api} csrf_token={csrf_token}/>), domContainer);
}


function collapseMenu(collapse) {
    if (collapse) {
        $('#left-sidebar').addClass('collapsed');
        $('#menu-fold-trigger').addClass('collapsed');

        $('#left-sidebar li').each((i, e) => {
            new Foundation.Tooltip($(e));
        });

    } else {
        $('#left-sidebar').removeClass('collapsed');
        $('#menu-fold-trigger').removeClass('collapsed');

        $('#left-sidebar li').each((i, e) => {
            $(e).foundation('_destroy');

        });
    }
}


function initMenu() {
    let shouldCollapse = false;

    if (typeof (Storage) !== "undefined" && window.sessionStorage.getItem('menu-collapsed') !== null) {
        //user has explicitly set the menu state
        shouldCollapse = JSON.parse(window.sessionStorage.getItem('menu-collapsed'));

    } else {
        //check width of viewport instead
        const windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        shouldCollapse = windowWidth < 640;
    }

    if (shouldCollapse) {
        collapseMenu(shouldCollapse);
    }
}

function toggleMenu() {
    const shouldCollapse = !($('#left-sidebar').hasClass('collapsed'));
    collapseMenu(shouldCollapse);

    if (typeof (Storage) !== "undefined") {
        window.sessionStorage.setItem('menu-collapsed', shouldCollapse);
    }
}

window.app = {
    showTotalMemberStatistics,
    showNewMemberStatistics,
    showTotalProjectStatistics,
    showNewProjectStatistics,
    showSliceCards,
    showAuthorizeSpeaksFor,
    showProjectInviteUrl,
    toggleMenu
};


$().ready(function () {
    $(document).foundation();
    initMenu();
});
