import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from "moment/moment";


class SliceCard extends Component {
    render() {
        const {slice_name, uid, urn, creation, expiration, slivers, rspec, manifest_rspec, project_name} = this.props;

        const slivers_by_testbed = {};

        slivers.forEach(sliver => {
            const testbedName = sliver['aggregate_friendly_name'] || "Unknown Testbed";
            console.log(testbedName);
            if (!slivers_by_testbed[testbedName]) {
                slivers_by_testbed[testbedName] = []
            }

            slivers_by_testbed[testbedName].push(sliver)
        });


        return (
            <div className="slice-card">
                <h5 className="slice-name"><a href={`/experiment/${uid}`}>{slice_name}</a><span
                    className="project-name">in <a href={`/project/${project_name}`}><em>{project_name}</em></a></span>
                </h5>
                <div className="slice-creation-wrapper">
                                        <span className="title">
                                            <span className="slice-created-icon"></span> Created:
                                        </span>{moment(creation).format('llll')}
                </div>
                <div className="slice-expiration-wrapper">
                                        <span className="title">
                                            <span className="slice-expiration-icon"></span> Expiring:
                                        </span>{moment(expiration).format('llll')}
                </div>
                <div className="slice-slivers-wrapper">
                                        <span className="title">
                                            <span className="slice-slivers-icon"></span> Testbeds:
                                        </span>

                    {Object.keys(slivers_by_testbed).length > 0 ?
                        <ul> {Object.entries(slivers_by_testbed).map(([testbed_name, slivers]) => (
                            <li key={testbed_name}>{testbed_name} ({slivers.length} slivers)</li>))}</ul> : <span>None</span>}

                </div>

                <div className="slice-rspec-wrapper">
                                        <span className="title">
                                            <span className="slice-rspec-icon"></span> RSpec:
                                        </span> {rspec ? (<a className="slice-rspec-download-link button tiny hollow"
                                                             href={`/experiment/${uid}/request.rspec`}>Request RSpec</a>) : "Not available"} {manifest_rspec ? (<a className="slice-rspec-download-link button tiny hollow"
                                                             href={`/experiment/${uid}/manifest.rspec`}>Manifest RSpec</a>) : null}
                </div>
                {moment(expiration).isAfter(moment.now()) ? (
                    <div className="slice-jfed-button-wrapper">
                        <a href={`jfed://slice_urn=${encodeURIComponent(urn)}`}
                           className="button hollow small open-jfed-button">Open in jFed</a>
                    </div>
                ) : null}


                {/*<div className="slice-slivers-wrapper">*/}
                {/*                        <span className="title">*/}
                {/*                            <span className="slice-slivers-icon"></span>Nodes:*/}
                {/*                        </span>*/}
                {/*    {% if  slice_rspecs[slice.urn] and len(slice_rspecs[slice.urn]) %}*/}
                {/*    {{slice_rspecs[slice.urn][0].metadata.node_count}}*/}
                {/*    {% else %}*/}
                {/*    Not available*/}
                {/*    {% endif %}*/}
                {/*</div>*/}
            </div>
        );
    }
}


SliceCard.propTypes = {
    name: PropTypes.string,
    creation: PropTypes.string,
    expiration: PropTypes.string,
    slivers: PropTypes.any
};

export default SliceCard;