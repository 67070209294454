import React, {Component} from "react";

export default class ProjectInviteUrl extends Component {


    constructor() {
        super();
        this.state = {
            working: false, working_message: 'Working ...', error: false, error_message: '',
            base_invite_url: null, auto_approve_key: null
        };

        this.updateUrl = this.updateUrl.bind(this);
    }

    componentDidMount() {
        const {inviteUrlApi} = this.props;
        fetch(inviteUrlApi, {method: 'GET'})
            .then(result => result.json())
            .then(result => this.setState(result));
    }

    updateUrl(auto_approve_invited_members) {
        const {inviteUrlApi, csrf_token} = this.props;

        this.setState({working: true, working_message: "Retrieving new invite URL"});

        const formdata = new FormData();
        formdata.append('auto_approve_invited_members', auto_approve_invited_members);

        fetch(inviteUrlApi, {
            method: 'POST',
            headers: {'X-CSRFToken': csrf_token},
            body: formdata
        })
            .then(result => result.json())
            .then(result => {
                this.setState({...result, working: false});
            }).catch(error => {
            this.setState({error: true, error_message: error})
        })
    }

    render() {
        const {base_invite_url, auto_approve_key, working, working_message, error, error_message} = this.state;

        if (!base_invite_url) {
            return null;
        }

        const url = new URL(base_invite_url);

        if (auto_approve_key) {
            url.searchParams.set('key', auto_approve_key);
        }


        return (<div className="project-invite-url">
            <p>Invite URL</p>

            {error ? (
                <div className="callout alert">
                    <p style={{textAlign: "center"}}>
                        <i className="fal fa-exclamation-triangle"
                           style={{marginRight: 8}}></i>{error_message}</p>
                </div>
            ) : null}


            {working ? (
                <span className="invite-url">
                        <i className="fal fa-spinner fa-spin"
                           style={{marginRight: 8}}></i>{working_message}
                </span>
            ) : <span className="invite-url">{url.href}</span>}




            <div>
                <input id="auto-approve-invited-members" type="checkbox"
                       onChange={e => this.updateUrl(e.target.checked)} checked={auto_approve_key !== null} disabled={working}/><label
                htmlFor="auto-approve-invited-members"  title="Enabling the auto-approve functionality will generate a random key that is appended to the invite-url. Disabling will permanently invalidate this auto-approve key.">Auto-approve
                members registering through invite URL</label>
            </div>
        </div>);

        return (<div>
            {working ? (
                <div className="callout primary">
                    <p style={{textAlign: "center"}}>
                        <i className="fal fa-spinner fa-spin"
                           style={{marginRight: 8}}></i>{working_message}</p>
                </div>
            ) : null}

            <form>
                <a id="authorize-button"
                   className={`button expand primary large oauth-${working ? 'authorizing' : 'authorize'}-button`}
                   onClick={this.authorize}>{working ? 'Authorizing' : 'Authorize'}</a>
                <a id="deny-button"
                   className="button expand alert large oauth-deny-button">Deny</a>
            </form>
        </div>);
    }
}