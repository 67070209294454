import React, {Component} from 'react';
import PropTypes from "prop-types";
import {
    XAxis,
    YAxis,
    ResponsiveContainer,
    Legend,
    AreaChart,
    Area,
    Tooltip,
    BarChart,
    Bar,
    Brush,
    CartesianGrid
} from 'recharts';
import {schemePaired, schemeTableau10} from 'd3-scale-chromatic';
import * as moment from 'moment';

export class MemberTotals extends Component {

    render() {
        const {data, prettyNames} = this.props;

        const totals = data.map(entry => {
            return {date: new Date(entry.date).getTime(), ...entry.totals}
        });

        const all_keys = Object.keys(totals[totals.length - 1]).filter(key => key !== 'date').sort();

        return (
            <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={totals} syncId="members">
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date"
                           scale="time"
                           type="number" domain={['dataMin', 'dataMax']}
                           tickFormatter={(date) => moment(date).format('YYYY-MM-DD')}

                    />
                    <YAxis allowDecimals={false}/>
                    <Tooltip labelFormatter={(date) => moment(date).format('YYYY-MM-DD')}/>
                    <Brush dataKey="date" height={30} tickFormatter={(date) => moment(date).format('YYYY-MM-DD')}/>

                    <Legend verticalAlign="top" height={36}/>
                    {all_keys.map((key, idx) =>
                        (<Area stackId="total" name={prettyNames[key]} key={key} type="monotone" dataKey={key}
                               stroke={schemePaired [idx * 2 + 1]}
                               fill={schemePaired [idx * 2]}

                        />))}
                </AreaChart>
            </ResponsiveContainer>
        );
    }
}

export class NewMemberStatistics extends Component {

    render() {
        const {data, prettyNames} = this.props;

        const new_members = data.map(entry => {
            return {date: new Date(entry.date).getTime(), ...entry.new}
        });

        const all_keys = Object.keys(prettyNames);

        return (
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={new_members} syncId="members">
                    <XAxis dataKey="date" scale="time" type="number" domain={['dataMin - 86400000', 'dataMax']}
                           tickFormatter={(date) => moment(date).format('YYYY-MM-DD')}

                    />
                    <YAxis allowDecimals={false}/>

                    <Tooltip labelFormatter={(date) => moment(date).format('YYYY-MM-DD')}/>
                    <Legend verticalAlign="top" height={36}/>
                    {all_keys.map((key, idx) =>
                        (<Bar stackId="new" name={prettyNames[key]} key={key} dataKey={key}
                              fill={schemePaired [idx * 2]}

                        />))}
                </BarChart>
            </ResponsiveContainer>
        );
    }
}
