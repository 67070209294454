import React, {Component} from 'react';
import PropTypes from "prop-types";
import SliceCard from "./SliceCard";
import Select, {Option} from "rc-select/es/index";
//import "rc-select/assets/index.less";
import "../../sass/components/rc-select.less";

import CheckBox from "rc-checkbox";
import moment from "moment";
import {createBrowserHistory} from "history";


//import "rc-checkbox/assets/index.css";


const history = createBrowserHistory();

function getFilterAndSort() {
    const urlSearch = new URLSearchParams(history.location.search);

    const sort = urlSearch.get("sort") || "creation";
    const order = urlSearch.get("order") || "desc";
    const projects = 
        (urlSearch.get("projects") && urlSearch.get("projects").length) ?
            urlSearch.get("projects").split(',')
            : [];

    return {sort, order, projects};
}

function setFilterAndSort(newFilterAndSort) {

    const values = {...getFilterAndSort(), ...newFilterAndSort};
    let newPath = `${history.location.pathname}?sort=${values.sort}&order=${values.order}`;

    if (values.projects && values.projects.length) {
        newPath += `&projects=${values.projects.join(',')}`;
    }

    history.push(newPath);
}


class SortByButton extends Component {


    render() {
        const {name, title} = this.props;
        const {sort, order} = getFilterAndSort();

        let isActive = name === sort;

        const updateOrder = () => {
            const {name} = this.props;
            const {sort, order} = getFilterAndSort();

            //only flip order if current sort is active
            let newOrder = isActive ? order === "desc" ? "asc" : "desc" : order;

            setFilterAndSort({sort: name, order: newOrder});
        };

        return (<a className={`button ${isActive ? 'active' : 'hollow'}`} onClick={updateOrder}>{title}

            {isActive ? (<span style={{marginLeft: 8}}>{
                    order === 'desc' ? <i className="fal fa-caret-down"/> : <i className="fal fa-caret-up"/>
                }</span>)
                : null}

        </a>);
    }
}

SortByButton.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
};

class SliceCards extends Component {

    constructor() {
        super();

        this.state = {
            showExpired: true,
            showEmpty: true,
        };


    }

    componentDidMount() {
        this.unlisten = history.listen((location, action) => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }


    render() {

        console.log('render');
        const {projects, slices} = this.props;
        const {sort, order, projects: showProjects} = getFilterAndSort();

        const {showExpired, showEmpty} = this.state;


        let filteredSlices = slices.sort(
            (a, b) => {
                if (sort === "creation") {
                    return a.creation.localeCompare(b.creation)
                } else if (sort === "expiration") {
                    return a.expiration.localeCompare(b.expiration)
                } else if (sort === "name") {
                    return a.slice_name.localeCompare(b.slice_name, undefined, {sensitivity: "base"})
                } else if (sort === "resource_count") {
                    //todo fix
                    return a.creation.localeCompare(b.creation)
                } else {
                    throw Error("Sort not defined")
                }
            }
        );

        if (order !== "asc") {
            filteredSlices = filteredSlices.reverse();
        }

        if (!showExpired) {
            filteredSlices = slices.filter(slice => moment().isBefore(moment(slice.expiration)));
        }

        // if (showProjects && showProjects.length) {
        //     filteredSlices = slices.filter(slice => showProjects.includes(slice.project_name));
        // }


        return (
            <div>
                <div className="slices-sort-wrapper">
                    <div className="slices-filter-wrapper">
                        <div className="filter">
                            <div className="name">Filter:</div>

                            <CheckBox id="show-expired" checked={showExpired}
                                      onChange={() => this.setState({showExpired: !showExpired})}/>
                            <label className="filter-checkbox-label"
                                   htmlFor="show-expired">Show Expired</label>
                            <CheckBox id="show-empty" checked={showEmpty}
                                      onChange={() => this.setState({showEmpty: !showEmpty})}/>
                            <label className="filter-checkbox-label" htmlFor="show-empty">Show Empty</label>
                        </div>
                        {/* <div className="filter">
                            <label htmlFor="projects">
                                <div className="name">Project:</div>
                            </label>
                            <Select id="projects" mode="tags" className="projects-select"
                                    prefixCls="idlab-portal-select" showSearch={false}
                                    onChange={(value) => {
                                        setFilterAndSort({projects: value});
                                    }} value={showProjects || projects.map(project => project.project_name)}>
                                {projects.map(project =>
                                    (<Option key={project.project_name}
                                             value={project.project_name}>{project.project_name}</Option>))}
                            </Select>
                        </div> */}
                    </div>
                    <hr/>
                    <div>
                        <div className="name">Sort By:</div>
                        <div className="button-group sort-button-group">
                            <SortByButton title="Name" name="name"/>
                            <SortByButton title="Creation Date" name="creation"/>
                            <SortByButton title="Expiration Date" name="expiration"/>
                            <SortByButton title="Resource Count" name="resource_count"/>
                        </div>
                    </div>
                </div>
                {filteredSlices.length ? (
                    <div className="slice-cards">
                        {filteredSlices.map(slice => <SliceCard key={slice.slice_name} {...slice}/>)}
                    </div>
                ) : (
                    <div className="no-slices">
                        <div className="callout warning">{slices.length > filteredSlices.length ?
                            <span>{slices.length - filteredSlices.length} experiments do not match your filters.</span> :
                            <span>You currently don't have any active experiments.</span>}</div>
                    </div>
                )}
            </div>
        );
    }
}

//
// SliceCards.propTypes = {
//     projects: PropTypes.arrayOf(PropTypes.shape({
//         project_name: PropTypes.string
//     })),
//     slices: PropTypes.array(PropTypes.shape(SliceCard.propTypes))
// };


export default SliceCards;