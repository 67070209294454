import React, {Component} from "react";

export default class AuthorizeSpeaksFor extends Component {


    constructor() {
        super();
        this.state = {working: false, working_message: 'Working ...', error: false, error_message: ''}

        this.authorize = this.authorize.bind(this);
        this.deny = this.deny.bind(this);
    }

    authorize() {
        const {csrf_token, tool} = this.props;

        this.setState({working: true, working_message: "Retrieving login certificate"});

        const formdata = new FormData();
        formdata.append('authorize', 'true');

        fetch(window.location.href, {
            method: 'POST',
            headers: {'X-CSRFToken': csrf_token},
            body: formdata
        })
            .then(result => result.json())
            .then(info_to_post => {
                this.setState({working_message: "Sending login certificate to " + tool.name});

                console.log("Posting the following to tool:", info_to_post);
                try {
                    window.opener.postMessage(info_to_post, tool.post_origin);
                    window.close();
                } catch(e) {
                    console.log(e);
                    this.setState({
                        working: false,
                        error: true,
                        error_message: "Failed to send certificate to " + tool.name
                    });
                }
            })
    }

    deny() {
        window.close();
    }

    render() {
        const {working, working_message, error, error_message} = this.state;

        return (<div>
            {working ? (
                <div className="callout primary">
                    <p style={{textAlign: "center"}}>
                        <i className="fal fa-spinner fa-spin"
                           style={{marginRight: 8}}></i>{working_message}</p>
                </div>
            ) : null}

            {error ? (
                <div className="callout alert">
                    <p style={{textAlign: "center"}}>
                        <i className="fal fa-exclamation-triangle"
                           style={{marginRight: 8}}></i>{error_message}</p>
                </div>
            ) : null}

            <form>
                <a id="authorize-button"
                   className={`button expand primary large oauth-${working ? 'authorizing' : 'authorize'}-button`}
                   onClick={this.authorize}>{working ? 'Authorizing' : 'Authorize'}</a>
                <a id="deny-button"
                   className="button expand alert large oauth-deny-button">Deny</a>
            </form>
        </div>);
    }
}